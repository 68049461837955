html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

#app-root {
  position: relative;
  z-index: 0;
  min-width: 1024px;
}

@media print {
  html, #app-root {
    background: white;
  }
}

.MuiTab-root {
  min-width: 100px !important;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
